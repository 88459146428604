import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import './style.css'

const Welcome = () => {
    return (
        <div className="welcome-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="welcome-content">
                            <h2>We can't wait to celebrate with you!</h2>
                            <p>Get ready for a cocktail of love, laughter, and happily-ever-afters.<br></br>
                                Within these virtual walls, you will find a glimpse of our love, the significance of our union, and the information you need to join us in celebrating this milestone.<br></br><br></br>
                                See you at the wedding!</p>
                            <div className="btn"><AnchorLink href='#rsvp'>RSVP</AnchorLink></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Welcome;