import React, { Component } from 'react';
import axios from 'axios'
import { toast } from 'react-toastify';
import './style.css'
import 'react-toastify/dist/ReactToastify.css';

class Rsvp extends Component {
    state = {
        name1: '',
        surname1: '',
        whatsapp1: '',
        email1: '',
        name2: '',
        surname2: '',
        whatsapp2: '',
        email2: '',
        notes: ''
    }
    errors = {}

    onName1Change(event) {
        this.setState({ name1: event.target.value })
    }
    onSurname1Change(event) {
        this.setState({ surname1: event.target.value })
    }
    onWhatsapp1Change(event) {
        this.setState({ whatsapp1: event.target.value })
    }
    onEmail1Change(event) {
        this.setState({ email1: event.target.value })
    }
    onName2Change(event) {
        this.setState({ name2: event.target.value })
    }
    onSurname2Change(event) {
        this.setState({ surname2: event.target.value })
    }
    onWhatsapp2Change(event) {
        this.setState({ whatsapp2: event.target.value })
    }
    onEmail2Change(event) {
        this.setState({ email2: event.target.value })
    }
    onNotesChange(event) {
        this.setState({ notes: event.target.value })
    }



    submitHandler = (e) => {
        e.preventDefault();

        const { error } = this.errors;
        if (this.state.name1 === '') {
            error.name1 = "Please enter first guest name!";
        }
        if (this.state.surname1 === '') {
            error.surname1 = "Please enter first guest surname!";
        }
        if (this.state.whatsapp1 === '') {
            error.whatsapp1 = "Please enter first guest whatsapp number!";
        }
        if (this.state.email1 === '') {
            error.email1 = "Please enter first guest email address!";
        }
        if (error) {
            this.setState(this.errors, { error });
        }

        if (this.errors.name1 === '' && this.errors.surname1 === '' && this.errors.whatsapp1 === '' && this.errors.email1 === '') {
            this.setState(this.errors, { error: {} });
        }

        axios({
            method: "POST",
            url: "https://wedding.codelevel39.it/api/mailsender.php",
            data: JSON.stringify(this.state)
        }).then((response) => {
            console.log('response', response);
            if (response.status === 200) {
                toast.success("Message Sent!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: "Bounce"
                });
            }
        },
            (error) => {
                toast.error('Message failed to send!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: "Bounce"
                });
                toast.error(error.response.data, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: "Bounce"
                });
            })
    }

    render() {
        return (
            <div id="rsvp" className="rsvp-area go-rsvp-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                            <div className="rsvp-wrap">
                                <div className="col-12">
                                    <div className="section-title section-title4 text-center">
                                        <h2>Be Our Guest</h2>
                                        <p>Please reserve before July 31st, 2024</p>
                                    </div>
                                </div>
                                <form onSubmit={this.submitHandler.bind(this)} method="POST">
                                    <div className="contact-form form-style">
                                        <div className="row">
                                            <div className="col-12 col-sm-12">
                                                <h6>Guest #1</h6>
                                                </div>
                                            <div className="col-12  col-sm-6">
                                                <input id="name1" name="name1" type="text" placeholder="Name*" value={this.state.name1} onChange={this.onName1Change.bind(this)} />
                                                <p>{this.errors.name1 ? this.errors.name1 : ''}</p>
                                            </div>
                                            <div className="col-12  col-sm-6">
                                                <input id="surname1" name="surname1" type="text" placeholder="Surname*" value={this.state.surname1} onChange={this.onSurname1Change.bind(this)} />
                                                <p>{this.errors.surname1 ? this.errors.surname1 : ''}</p>
                                            </div>
                                            <div className="col-12  col-sm-6">
                                                <input id="whatsapp1" name="whatsapp1" type="text" placeholder="Whatsapp*" value={this.state.whatsapp1} onChange={this.onWhatsapp1Change.bind(this)} />
                                                <p>{this.errors.whatsapp1 ? this.errors.whatsapp1 : ''}</p>
                                            </div>
                                            <div className="col-12  col-sm-6">
                                                <input id="email1" name="email1" type="text" placeholder="Email*" value={this.state.email1} onChange={this.onEmail1Change.bind(this)} />
                                                <p>{this.errors.email1 ? this.errors.email1 : ''}</p>
                                            </div>

                                            <div className="col-12 col-sm-12">
                                                <h6>Guest #2 (ONLY if stated on your invitation)</h6>
                                            </div>
                                            <div className="col-12  col-sm-6">
                                                <input id="name2" name="name2" type="text" placeholder="Name" value={this.state.name2} onChange={this.onName2Change.bind(this)} />
                                            </div>
                                            <div className="col-12  col-sm-6">
                                                <input id="surname2" name="surname2" type="text" placeholder="Surname" value={this.state.surname2} onChange={this.onSurname2Change.bind(this)} />
                                            </div>
                                            <div className="col-12  col-sm-6">
                                                <input id="whatsapp2" name="whatsapp2" type="text" placeholder="Whatsapp" value={this.state.whatsapp2} onChange={this.onWhatsapp2Change.bind(this)} />
                                            </div>
                                            <div className="col-12  col-sm-6">
                                                <input id="email2" name="email2" type="text" placeholder="Email" value={this.state.email2} onChange={this.onEmail2Change.bind(this)} />
                                            </div>

                                            <div className="col-12 col-sm-12 mt-4">
                                                <h6>Feel free to add any comment!</h6>
                                                <textarea className="contact-textarea" id="notes" name="notes" placeholder="Message" value={this.state.notes} onChange={this.onNotesChange.bind(this)}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-12 text-center">
                                            <button id="submit" type="submit" className="submit">Send RSVP</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Rsvp;