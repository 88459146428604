import React, { createContext, useContext, useState } from 'react';

const IntersectionContext = createContext();

export const useIntersection = () => useContext(IntersectionContext);

export const IntersectionProvider = ({ children }) => {
    const [isIntersecting, setIsIntersecting] = useState(false);

    return (
        <IntersectionContext.Provider value={{ isIntersecting, setIsIntersecting }}>
            {children}
        </IntersectionContext.Provider>
    );
};