import React, { useState, useEffect } from 'react';
import { useIntersection } from '../Contexts/IntersectionContext';
import './style.css';

const Typewriter = ({ text, delay }) => {
    const [currentText, setCurrentText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const { isIntersecting } = useIntersection();
    const [audio] = useState(new Audio('../../audio/prince-belair.mp3'));

    useEffect(() => {
        audio.load();
    }, [audio]);

    useEffect(() => {
        audio.loop = false;
    }, [audio])

    useEffect(() => {
        if (isIntersecting) {
            audio.play();
        } else {
            audio.pause();
            audio.currentTime = 0; // Reset audio to start
        }
        return () => {
            audio.pause();
            audio.currentTime = 0; // Ensure audio is reset when component unmounts
        };
    }, [isIntersecting, audio]);

    useEffect(() => {
        if (isIntersecting && currentIndex < text.length) {
            const timeout = setTimeout(() => {
                setCurrentText(prevText => prevText + text[currentIndex]);
                setCurrentIndex(prevIndex => prevIndex + 1);
            }, delay);
            return () => clearTimeout(timeout);
        }
        else if (!isIntersecting) {
            setCurrentText('');
            setCurrentIndex(0);
        }
    }, [isIntersecting, currentIndex, delay, text]);

    return (
        <div className="container">
            <div className="typewriter-text">
                <h2>{currentText}</h2>
            </div>
        </div>
    );
};

export default Typewriter;