import React from 'react'
import Sectiontitle from '../section-title'
import './style.css'
import heartbrush from '../../images/heartbrush.png'
import groomsman1 from '../../images/groomsman-Luca.jpg' 
import groomsman2 from '../../images/groomsman-Riccardo.jpg' 
import bridesmaid1 from '../../images/bridesmaid-Sinamile.jpg' 
import bridesmaid2 from '../../images/bridesmaid-Mahlatse.jpg' 

const TheGang = () => {
    return (
        <div id="thegang" className="about-wrap">
            <div className="couple-area section-padding pb-70">
                <Sectiontitle section={'The gang'} />
                <div className="container">
                    <div className="couple-wrap">
                        <div className="row">
                            <div className="col-lg-1 nomobile"></div>
                            <div className="col-lg-4 nomobile">
                                <div className="gang-title">
                                    <h3>Groomsmen</h3> 
                                </div>
                            </div>
                            <div className="col-lg-2 nomobile">
                                <div className="couple-shape">
                                    <img src={heartbrush} alt="shape" />
                                </div>
                            </div>
                            <div className="col-lg-4 nomobile">
                                <div className="gang-title">
                                    <h3>Bridesmaids</h3>
                                </div>
                            </div>
                            <div className="col-lg-1 nomobile"></div>
                            <div className="col-sm-12 mobile">
                                <div className="gang-title">
                                    <h3>Groomsmen</h3> 
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-12">
                                <div className="couple-wrap couple-wrap-2">
                                    <div className="couple-img">
                                        <img src={groomsman1} alt="thumb" />
                                    </div>
                                    <div className="couple-text">
                                        <div className="couple-content">
                                            <h3>Luca</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-12">
                                <div className="couple-wrap couple-wrap-2">
                                    <div className="couple-img">
                                        <img src={groomsman2} alt="thumb" />
                                    </div>
                                    <div className="couple-text">
                                        <div className="couple-content">
                                            <h3>Riccardo</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 mobile">
                                <div className="couple-shape">
                                    <img src={heartbrush} alt="shape" />
                                </div>
                            </div>
                            <div className="col-sm-12 mobile">
                                <div className="gang-title">
                                    <h3>Bridesmaids</h3>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-12">
                                <div className="couple-wrap couple-wrap-3">
                                    <div className="couple-img couple-img-2">
                                        <img src={bridesmaid1} alt="thumb" />
                                    </div>
                                    <div className="couple-text">
                                        <div className="couple-content">
                                            <h3>Sinamile</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-12">
                                <div className="couple-wrap couple-wrap-3">
                                    <div className="couple-img couple-img-2">
                                        <img src={bridesmaid2} alt="thumb" />
                                    </div>
                                    <div className="couple-text">
                                        <div className="couple-content">
                                            <h3>Mahlatse</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default TheGang;