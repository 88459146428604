import React from 'react';
import {Link} from 'react-router-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import MobileMenu from '../../components/MobileMenu'

import './style.css'

const Header = () => {

    return(
        <div className="Header_root" id='home'>
            <div className="header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-10">
                            <div className="logo">
                                <h2><Link to='#home'>Michele<span><i className="fa fa-heart" aria-hidden="true"></i></span>Nompumelelo</Link></h2>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="header-menu d-lg-block d-none">
                                <ul className="mobail-menu d-flex">
                                    <li><AnchorLink href='#home'>Home</AnchorLink></li>
                                    <li><AnchorLink href='#us'>US</AnchorLink></li>
                                    <li><AnchorLink href='#thegang'>The gang</AnchorLink></li>
                                    <li><AnchorLink href='#whenwhere'>When & Where</AnchorLink></li>
                                    <li><AnchorLink href='#faq'>Q & A</AnchorLink></li>
                                    {/* <li><AnchorLink href='#rsvp'>RSVP</AnchorLink></li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-2">
                            <MobileMenu/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;
