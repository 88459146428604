import React, { useEffect, useRef } from 'react';
import Sectiontitle from '../section-title';
import Typewriter from '../typewriter';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useIntersection } from '../Contexts/IntersectionContext';
import './style.css';

import img1 from '../../images/gallery-01.jpg';
import img2 from '../../images/gallery-02.jpg';
import img3 from '../../images/gallery-03.jpg';
import img4 from '../../images/gallery-04.jpg';
import img5 from '../../images/gallery-05.jpg';
import img6 from '../../images/gallery-06.jpg';
import img7 from '../../images/gallery-07.jpg';
import img8 from '../../images/gallery-08.jpg';
import img9 from '../../images/gallery-09.jpg';

const Images = [
    { img: img1 },
    { img: img2 },
    { img: img3 },
    { img: img4 },
    { img: img5 },
    { img: img6 },
    { img: img7 },
    { img: img8 },
    { img: img9 },
];

const Gallery = (props) => {
    const [open, setOpen] = React.useState(false);
    const elementRef = useRef(null);
    const { setIsIntersecting } = useIntersection();

    useEffect(() => {
        const currentElement = elementRef.current;
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsIntersecting(entry.isIntersecting);
            },
            {
                threshold: 0.1, // Adjust this value as needed
            }
        );

        if (currentElement) {
            observer.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                observer.unobserve(currentElement);
            }
        };
    }, [setIsIntersecting]);

    return (
        <div id="us" className="Gallery-section section-padding pb-70" ref={elementRef}>
            <Sectiontitle section={'US'} />
            <Typewriter text={"Now, this is a story all about how our lives got flipped-turned upside down and we'd like to take a minute just sit right there we'll tell you how we plan on becomin' MR and MRS   A.."} delay={100} />
            <div className="container">
                <div className="row">
                    <ResponsiveMasonry columnsCountBreakPoints={{ 575: 1, 750: 3, 900: 3 }}>
                        <Masonry gutter="30px">
                            {Images.map((image, i) => (
                                <div className="gallery-img" onClick={() => setOpen(true)} key={i}>
                                    <img src={image.img} alt="" className="img img-responsive" />
                                </div>
                            ))}
                        </Masonry>
                    </ResponsiveMasonry>
                </div>
            </div>
            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={[
                    { src: img1 },
                    { src: img2 },
                    { src: img3 },
                    { src: img4 },
                    { src: img5 },
                    { src: img6 },
                    { src: img7 },
                    { src: img8 },
                    { src: img9 },
                ]}
            />
        </div>
    );
};

export default Gallery;