import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css'


class SimpleSlider extends Component {
    render() {
        var settings = {
            dots: false,
            arrows: false,
            speed: 5000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            fade: true,
            infinite: true,
            lazyLoad: "ondemand",
        };
        return (
            <Slider {...settings}>
                <div id="home" className="item1">
                    <div className="container">
                        <div className="slide-content slide-1">
                            <div className="slide-title">
                                <h2>We're getting married!</h2>
                            </div>
                            <div className="slide-text">
                                <p>07 September 2024</p>
                            </div>
                        </div>
                    </div>
                </div> 
                <div className="item2">
                    <div className="container">
                        <div className="slide-content slide-2">
                            <div className="slide-title">
                                <h2>We're getting married!</h2>
                            </div>
                            <div className="slide-text">
                                <p>07 September 2024</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        );
    }
}

export default SimpleSlider;