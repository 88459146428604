import React, { Component } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import './style.css';

export default class MobileMenu extends Component {
    state = { isMenuShow: false }

    menuHandler = () => {
        this.setState({
            isMenuShow: !this.state.isMenuShow
        })
    }

    render() {
        const { isMenuShow } = this.state;
        return (
            <div>
                <div className={`mobileMenu ${isMenuShow ? 'show' : ''}`}>
                    <div className="close" onClick={this.menuHandler}><i className='fa fa-close'></i></div>
                    <div className="logo2">
                        <h2><AnchorLink href='#home'>Michele & Nompumelelo</AnchorLink></h2>
                    </div>
                    <ul className="responsivemenu">
                        <li><AnchorLink href='#home' onClick={this.menuHandler}>Home</AnchorLink></li>
                        <li><AnchorLink href='#us' onClick={this.menuHandler}>US</AnchorLink></li>
                        {/* <li><AnchorLink href='#thegang' onClick={this.menuHandler}>The gang</AnchorLink></li> */}
                        <li><AnchorLink href='#whenwhere' onClick={this.menuHandler}>When & Where</AnchorLink></li>
                        <li><AnchorLink href='#faq' onClick={this.menuHandler}>Q & A</AnchorLink></li>
                        <li><AnchorLink href='#rsvp' onClick={this.menuHandler}>RSVP</AnchorLink></li>
                    </ul>
                </div>
                <div className="showmenu" onClick={this.menuHandler}><i className="fa fa-bars" aria-hidden="true"></i></div>
            </div>
        )
    }
}
