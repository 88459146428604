import React from 'react'
import Sectiontitle from '../section-title'
import './style.css'

const Faq = () => {
    return (
        <div id="faq" className="service-area section-padding">
            <div className="container">
                <Sectiontitle section={'Questions and Answers'} />
                <div className="faq row">
                    <h3 className="col-lg-5">What is the wedding date, time, and location?</h3>
                    <p className="col-lg-7">Because you might be a lil' under the weather, here we go again: The IMPORTANT DATE is 7 SEPTEMBER 2024, 1PM @ BELL & BLOSSOM. <br></br>'African time' DOES NOT apply</p>
                    <h3 className="col-lg-5 mt-3">Do I need to RSVP?</h3>
                    <p className="col-lg-7 mt-3">Yes, very important!<br></br>
                        We kindly request that all guests RSVP by 31 July 2024. You can do this by visiting the "RSVP" section of our website.<br></br>
                        Don't forget to include your contact details in case we change the location to Mars and you miss the updates 😀</p>
                    <h3 className="col-lg-5 mt-3">Can I bring a plus one?</h3>
                    <p className="col-lg-7 mt-3">Unfortunately, NO. We have reserved space ONLY for our specified invited guests. You know, the one whose name /s appear on the invite?! Yes, you!</p>
                    <h3 className="col-lg-5 mt-3">Can I bring my children?</h3>
                    <p className="col-lg-7 mt-3">Even if you promised they won't run around and bump the cake stand, we regrettably have to say, NO.</p>
                    <h3 className="col-lg-5 mt-3">What is the dress code / theme?</h3>
                    <p className="col-lg-7 mt-3">Proudly African! Because we are a diverse couple, we would like to express the beauty of our country's culture-also given that it is a traditional wedding celebration.<br></br>
                        Please dress appropriately and comfortably for the occasion.</p>
                    <h3 className="col-lg-5 mt-3">Are there any dietary restrictions that the couple should be aware of?</h3>
                    <p className="col-lg-7 mt-3">Please inform us of any dietary restrictions when you RSVP, so we can try our best to see how to accommodate your needs.</p>
                    <h3 className="col-lg-5 mt-3">Will there be a gift registry?</h3>
                    <p className="col-lg-7 mt-3">We have made space for your creative juices to take over so you may gift as your heart pleases. We will also provide a 'donation box' for the ones who have expressed preferring to gift large, undisclosed amount of notes!</p>
                    <h3 className="col-lg-12 mt-5">Further information will be shared as the date draws nearer… We can't wait to see you!</h3>
                </div>
            </div>
        </div>
    )
}

//export default Faq;

const Qna = () => {
    return (
        <div id="faq" className="service-area section-padding">
            <div className="container">
                <Sectiontitle section={'Questions and Answers'} />
                <div className="faq row">
                    <h5 className='col-12'>Thank you for scheduling your time to spend this special day with us. <br></br>We have some IMPORTANT INFORMATION to share with you, please familiarise yourselves, so we are all prepared for the day.</h5> 
                    <h5 className='col-12'>Don't hesitate to reach out to your dedicated assistant if you have any questions.</h5>
                    <h5 className='col-12'>Michele ♥️ Nompumelelo</h5>
                    <br></br><br></br><br></br>
                    <h3 className="col-lg-5">What is the wedding date, time, and location?</h3>
                    <p className="col-lg-7">Here we go again: The IMPORTANT DATE is 7 SEPTEMBER 2024, 1PM @ BELL & BLOSSOM. <br></br>'African time' DOES NOT apply</p>
                    <h3 className="col-lg-5">Appropriate arrival time?</h3>
                    <p className="col-lg-7">Gates open from 1 PM so please don't arrive earlier! <br></br>Everyone to be seated by 1:30 PM and no late coming into the chapel will be allowed</p>
                    <h3 className="col-lg-5">I have sent my RSVP but change of plans?</h3>
                    <p className="col-lg-7">Emergencies happen, we know. If you cannot come anymore, DO NOT extend our invitation to your buddy, it was strictly meant for you. DO CONTACT US ASAP as we still have aunts and uncles we wish to invite. Allow us to!</p>
                    <h3 className="col-lg-5">Is it okay to take photos during the ceremony and reception?</h3>
                    <p className="col-lg-7">We will be having an UNPLUGGED CEREMONY- We kindly ask that you refrain from taking photos during the ceremony, as we have hired a professional photographer for that. Phones down and on silent 🤫 <br></br>However, feel free to capture memories during the reception!</p>
                    <h3 className="col-lg-5">May I bring my own drinks/ food?</h3>
                    <p className="col-lg-7">NO. No outside drinks or food is permitted to enter. We have enough water to quench all your thirst and a cash bar is readily available for all your other thirsts!</p>
                    <h3 className="col-lg-5">Where will I park my Ferrari?</h3>
                    <p className="col-lg-7">Worry not, the venue’s got you! There’s ample, secured parking on the premises.</p>
                    <h3 className="col-lg-5 mt-3">What is the dress code / theme?</h3>
                    <p className="col-lg-7 mt-3">Proudly African! Because we are a diverse couple, we would like to express the beauty of our country's culture-also given that it is a traditional wedding celebration.<br></br>
                        Please dress appropriately and comfortably for the occasion.</p>
                    <h3 className="col-lg-5 mt-3">Will there be a gift registry?</h3>
                    <p className="col-lg-7 mt-3">We have made space for your creative juices to take over so you may gift as your heart pleases. We will also provide a 'donation box' for the ones who have expressed preferring to gift large, undisclosed amount of notes!</p>
                </div>
            </div>
        </div>
    )
}


export default Qna;