import React from 'react'
import Sectiontitle from '../section-title'
import bellandblossom from '../../images/bellandblossom.jpg'
import './style.css'

const Location = () => {
    return (
        <div id="whenwhere" className="service-area section-padding">
            <div className="container">
                <Sectiontitle section={'When & Where'} />
                <div className="service-area-menu">
                    <div className="Ceremony-wrap">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="ceromony-img">
                                    <img src={bellandblossom} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="ceromony-content">
                                    <h3>Wedding Ceremony @ Bell and Blossom</h3>
                                    <span>721 Klippan St, Montana, Pretoria, 0151, Sudafrica</span>
                                    <h4>Saturday, 7 September 2024, 1.00 PM</h4>
                                    <p> &quot;Second star to the right, this is the way<br></br>
                                        And then straight on til morning<br></br>
                                        Then you find the road by yourself<br></br>
                                        It leads to Neverland ... &quot;</p> </div>
                            </div>
                            <iframe title="map" className="col-lg-12 mt-3" height="600" frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=Bell+and+Blossom+Wedding+Venue&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Location;