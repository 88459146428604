import React from 'react';

// components
import TheGang from '../../components/the-gang';
import SimpleSlider from '../../components/hero';
import Welcome from '../../components/welcome-area';
import Location from '../../components/location';
import Gallery from '../../components/gallery';
import Rsvp from '../../components/rsvp';
import Navbar from '../../components/Navbar'
import Saveday from '../../components/countdown'
import Faq from '../../components/faq'
import { ToastContainer } from 'react-toastify';
import { IntersectionProvider } from '../../components/Contexts/IntersectionContext';

const Homepage = () => {
    return (
        <IntersectionProvider>
            <Navbar />
            <SimpleSlider />
            <Saveday />
            <Welcome />
            <Gallery />
            <TheGang />
            <Location />
            <Faq />
            {/* <Rsvp /> */}
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored" />
        </IntersectionProvider>
    )
}

export default Homepage;